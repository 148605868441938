import React, {useEffect} from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import * as TITLES from "../constants/titles";
import {PageProps} from "./Content";

const useStyles = makeStyles((theme) => {
    return {
        typographyH1: {
            textAlign: "center"
        }
    };
});

export default function Lectures(props: PageProps) {
    const classes = useStyles();

    useEffect(() => {
        props.onChangeContentTitle(TITLES.LECTURES_TITLE);
    }, [props]);

    return (
        <div style={{height: "calc(100%- 300px- 90px", backgroundColor: "#ffffff", borderTopRightRadius: "10px", borderTopLeftRadius: "10px", padding: "40px"}}>
            <div style={{margin: "auto", maxWidth: "900px"}}>
                <Typography variant="h1" component="div" className={classes.typographyH1}>
                    under<br/>construction
                </Typography>
            </div>
        </div>
    );
}