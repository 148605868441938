import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import * as TITLES from "../constants/titles";
import {PageProps} from "./Content";

const useStyles = makeStyles((theme) => ({
    developmentContainer: {
        width: "100%",
        maxWidth: "1280px",
        backgroundColor: "#ffffff",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
    },
    gridQuarterRow: {
        paddingTop: "1rem",
        paddingBottom: "1rem",
        [theme.breakpoints.up(800)]: {
            flexDirection: "row",
        },
        [theme.breakpoints.down(800)]: {
            flexDirection: "column",
        }
    },
    gridQuarter: {
        [theme.breakpoints.up(800)]: {
            width: "50%",
        },
        [theme.breakpoints.down(800)]: {
            width: "100%",
        }
    },
    gridLeftQuarter: {
        [theme.breakpoints.up(800)]: {
            paddingLeft: "0",
            paddingRight: "1rem"
        },
        [theme.breakpoints.down(800)]: {
            paddingLeft: "0",
            paddingRight: "0",
            paddingBottom: "1rem"
        }
    },
    gridRightQuarter: {
        [theme.breakpoints.up(800)]: {
            paddingLeft: "1rem",
            paddingRight: "0"
        },
        [theme.breakpoints.down(800)]: {
            paddingLeft: "0",
            paddingRight: "0",
            paddingTop: "1rem",
        }
    },
    cardMedia: {
        height: 140
    },
    cardContent: {
        minHeight: 150
    },
    typographyH1: {
        textAlign: "center"
    },
    contentBlock: {
        marginBottom: "20px"
    }
}));

export default function Development(props: PageProps) {
    const classes = useStyles();

    useEffect(() => {
        props.onChangeContentTitle(TITLES.DEVELOPMENT_TITLE)
    }, [props]);

    return (
        <div className={classes.developmentContainer}>
            <div style={{margin: "auto", padding: "40px", maxWidth: "900px"}}>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    Gerne unterstütze ich Sie und Ihr Entwicklungsteam in Ihren Softwareprojekten.
                    Ob Neuentwicklung, Migration oder Transformation Ihrer Altsysteme auf neuere
                    Technologien oder in Microservice-Architekturen.
                </Typography>
                <Typography variant="h6" component="div">
                    <strong>Schwerpunkte:</strong>
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    <ul>
                        <li>Agile Softwareentwicklung</li>
                        <li>Architekturen und fachlicher Schnitt</li>
                        <li>Modellierung mit Domain Driven Design</li>
                        <li>Jakarta EE (Java EE)</li>
                        <li>Microservice-Entwicklung durch Technologien wie Jakarta EE (Java EE), Eclipse MicroProfile,
                            Spring Boot, Docker und Kubernetes
                        </li>
                        <li>Client-Entwicklung durch Technologien wie React.js, Angular.js oder JSF</li>
                        <li>Verantwortung in mehreren Projekten für die Transformation von überalterten monolithischen
                            Systemen hin zu "state-of-the-art" Mircoservice-Umgebungen
                        </li>
                    </ul>
                </Typography>
                <Typography variant="h6" component="div">
                    <strong>Technologien:</strong>
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    <strong>Programmierung:</strong>
                    <ul>
                        <li>Jakarta EE (Java EE)</li>
                        <ul>
                            <li>JPA (Hibernate/Eclipse Link)</li>
                            <li>CDI</li>
                            <li>JSF</li>
                            <li>JAX-WS/JAXB/SOAP</li>
                        </ul>
                        <li>Eclipse MicroProfile</li>
                        <li>RESTful, Pragmatic REST, OpenAPI 3.0</li>
                        <li>Spring Framework</li>
                        <li>Spring Boot</li>
                        <li>JUnit</li>
                        <li>iOS Objective C</li>
                        <li>Javascript, Node.js, HTML, CSS</li>
                        <ul>
                            <li>React.js</li>
                            <li>Angluar.js</li>
                            <li>Typescript</li>
                        </ul>
                        <li>WebSockets</li>
                    </ul>
                    <strong>Containerisierung</strong>
                    <ul>
                        <li>Docker</li>
                        <li>Kubernetes</li>
                    </ul>
                    <strong>Datenbanken</strong>
                    <ul>
                        <li>Oracle, PL/SQL</li>
                        <li>PostgreSQL</li>
                        <li>MySQL</li>
                        <li>MS SQL</li>
                    </ul>
                    <strong>Sonstiges</strong>
                    <ul>
                        <li>Domain Driven Design</li>
                        <li>Consumer Driven Contracts</li>
                        <li>Git, SVN</li>
                        <li>Gradle</li>
                        <li>Apache Maven</li>
                        <li>Apache Ant</li>
                        <li>Continuous Integration, Jenkins</li>
                        <li>Continuous Integration, Github Actions</li>
                        <li>IntelliJ IDEA</li>
                        <li>Eclipse IDE (&RCP)</li>
                        <li>JIRA</li>
                        <li>Confluence</li>
                    </ul>
                    <strong>Methodik</strong>
                    <ul>
                        <li>Anforderungsanalyse</li>
                        <li> Datenmodellierung</li>
                        <li>Konzeption</li>
                        <li>Paper Prototyping</li>
                    </ul>
                </Typography>
                {/*<Grid container>
                <Grid item container className={classes.gridQuarterRow}>
                    <Grid item className={classes.gridQuarter + " " + classes.gridLeftQuarter}>
                        <Card elevation={3}>
                            <CardMedia className={classes.cardMedia}
                                       image="/static/images/individualSoftwareDevelopment.jpg"/>
                            <CardContent className={classes.cardContent}>
                                <Typography variant={"h5"} component={"h2"}>
                                    Individualsoftwareentwicklung
                                </Typography>
                                <Typography variant={"body2"} component={"p"}>
                                    Ich biete Ihrem Unternehmen Unterst&uuml;tzung bei der softwareseitigen
                                    Abbildung wichtiger Unternehmensprozesse. Ein agiles Vorgehen liegt mir dabei
                                    sehr am Herzen, ebenso der Umgang mit Ihrer Fachdom&auml;ne nach den Prinzipien
                                    von Domain-Driven Design.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item className={classes.gridQuarter + " " + classes.gridRightQuarter}>
                        <Card elevation={3}>
                            <CardMedia className={classes.cardMedia}
                                       image={"/static/images/microprocessorDevelopment.jpg"}/>
                            <CardContent className={classes.cardContent}>
                                <Typography variant={"h5"} component={"h2"}>
                                    Microprocessor Programmierung
                                </Typography>
                                <Typography variant={"body2"} component={"p"}>
                                    Gerne unterst&uuml;tze ich Sie bei der Programmierung Ihrer Mikroprozessoren.
                                    Ich setze dabei gerne auf die AT Mega Reihe oder den Raspberry Pi.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid item container className={classes.gridQuarterRow}>
                    <Grid item className={classes.gridQuarter + " " + classes.gridLeftQuarter}>
                        <Card elevation={3}>
                            <CardMedia className={classes.cardMedia} image={"/static/images/houseAutomation.png"}/>
                            <CardContent className={classes.cardContent}>
                                <Typography variant={"h5"} component={"h2"}>
                                    Hausautomation
                                </Typography>
                                <Typography variant={"body2"} component={"p"}>
                                    Die iHome-Control Plattform, basierend auf dem Raspberry Pi, bietet eine
                                    einfache Ansteuerung von Schaltquellen mit der Möglichkeit eines Rückkanals.
                                    Zusätzlich wird die IKEA Tradfri integriert.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid item container className={classes.gridQuarterRow}/>
            </Grid>*/}
            </div>
        </div>
    );
}