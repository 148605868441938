import {Route, Switch} from "react-router";
import * as ROUTES from "../constants/routes";
import Lectures from "./Lectures";
import LegalNotice from "./LegalNotice";
import DataProtection from "./DataProtection";
import Home from "./Home";
import NotFound from "./NotFound";
import React, {PropsWithChildren} from "react";
import "./Content.css";
import {makeStyles} from "@material-ui/core/styles";
import Development from "./Development";

const useStyles = makeStyles((theme) => ({
    content: {
        display: "flex",
        flexDirection: "column",
        color: "rgb(105, 105, 105)",
        width: "100%",
        minHeight: "100%",
        maxWidth: "1280px",
        margin: "90px auto 0 auto"
    },
    contentTitleContainer: {
        height: "300px",
        display: "flex",
        flexDirection: "column"
    },
    contentTitle: {
        fontSize: "40px",
        fontWeight: 700,
        margin: "auto 30px",
        color: "#fff",
        textAlign: "center",
        wordWrap: "break-word"
    },
    contentContainer: {
        height: "100%"
    }
}));

export interface PageProps extends PropsWithChildren<any> {
    onChangeContentTitle(contentTitle: string): void;
}

interface ContentProps extends PropsWithChildren<any> {
    contentTitle: string;

    onChangeContentTitle(contentTitle: string): void;
}

export default function Content(props: ContentProps) {

    const {contentTitle, onChangeContentTitle} = props;

    const classes = useStyles();

    return (
        <div id="content" className={classes.content}>
            <div className={classes.contentTitleContainer}>
                <div className={classes.contentTitle}>{contentTitle}</div>
            </div>
            <div className={classes.contentContainer}>
                <Switch>
                    <Route exact
                           path={ROUTES.LECTURES}
                           component={() => (<Lectures onChangeContentTitle={onChangeContentTitle}/>)}/>
                    <Route exact
                           path={ROUTES.DEVELOPMENT}
                           component={() => (<Development onChangeContentTitle={onChangeContentTitle}/>)}/>
                    <Route exact
                           path={ROUTES.LEGAL_NOTICE}
                           component={() => (<LegalNotice onChangeContentTitle={onChangeContentTitle}/>)}/>
                    <Route exact
                           path={ROUTES.DATA_PROTECTION}
                           component={() => (<DataProtection onChangeContentTitle={onChangeContentTitle}/>)}/>
                    <Route exact
                           path="/"
                           component={() => (<Home onChangeContentTitle={onChangeContentTitle}/>)}/>
                    <Route component={NotFound}/>
                </Switch>
            </div>
        </div>
    );
}