import React from "react";

function NotFound() {
    return (
        <div style={{ display: "inline-flex", flexDirection: "column", width: "100%" }}>
            <div style={{ fontSize: "240px", color: "rgb(105, 105, 105)", marginLeft: "auto", marginRight: "auto" }}>404</div>
            <div style={{ fontSize: "90px", color: "rgb(105, 105, 105)", marginLeft: "auto", marginRight: "auto" }}>Not Found</div>
        </div>
    );
}

export default NotFound;