import {createMuiTheme} from "@material-ui/core";

const TdbTheme = createMuiTheme({
  overrides: {
  },
  palette: {
    primary: {
      main: "#4c627f",
    },
    text: {
      primary: "#000000",
    }
  }
});

export default TdbTheme;
