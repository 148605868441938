import React, {useState} from "react";
import "./App.css";
import * as TITLES from "./constants/titles"
import Header from "./pages/Header";
import Content from "./pages/Content";
import Footer from "./pages/Footer";

// const useStyles = makeStyles((theme) => ({
// }));

export type Sticky = {
    isSticky: boolean;
    handleChanges: boolean;
    offset: number;
};

export default function App() {
    const [contentTitle, setContentTitle] = useState(TITLES.HOME_TITLE);

    return (
        <>
            <Header />
            <Content contentTitle={contentTitle}
                     onChangeContentTitle={setContentTitle}/>
            <Footer />
        </>
    );
};
