import React from "react";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router";
import * as ROUTES from "../constants/routes";
import {makeStyles} from "@material-ui/core/styles";
import "./Footer.css"
import * as TITLES from "../constants/titles";

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: "rgb(64, 64, 64)",
        color: "rgb(255,255,255)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        maxWidth: "1280px",
        height: "90px",
        width: "100%",
        margin: "0 auto",
        position: "relative",
        [theme.breakpoints.down(900)]: {
            flexDirection: "column",
            padding: "30px",
            width: "calc(100% - 60px)",
            height: "150px"
        }
    },
    copyright: {
        color: "rgba(255,255,255,0.5)",
        minWidth: "140px",
        textTransform: "uppercase",
        margin: "35px 20px",
        fontSize: "14px",
        lineHeight: "20px",
        position: "absolute",
        bottom: "0",
        [theme.breakpoints.down(900)]: {
            padding: "6px 8px",
            left: "10px",
            marginBottom: "30px"
        }
    },
    footerLinkContainer: {
        margin: "auto 0 auto auto",
        "& .MuiButton-root.last": {
            marginRight: "0",
        },
        [theme.breakpoints.down(900)]: {
            margin: "0 auto 60px 0",
            display: "flex",
            flexDirection: "column"
        }
    },
    footerLink: {
        margin: "auto 10px auto 0",
        "& .MuiButton-label": {
            color: "#ffffff",
            fontSize: "14px",
            lineHeight: "20px"
        },
        [theme.breakpoints.down(900)]: {
            "& .MuiButton-label": {
                justifyContent: "start",
            },
        }
    }
}));

export default function Footer() {

    const classes = useStyles();
    const history = useHistory();

    return (
        <div id="footer" className={classes.footer}>
            <div className={classes.footerLinkContainer}>
                <Button className={classes.footerLink}
                        variant={"text"}
                        color={"primary"}
                        onClick={() => history.push(ROUTES.LEGAL_NOTICE)}>{TITLES.LEGAL_NOTICE_TITLE}</Button>
                <Button className={classes.footerLink}
                        variant={"text"}
                        color={"primary"}
                        onClick={() => history.push(ROUTES.DATA_PROTECTION)}>{TITLES.DATA_PROTECTION_TITLE}</Button>
            </div>
            <div className={classes.copyright}>Copyright 2021</div>
        </div>
    );
}